import logo from './logo.svg';
// import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import './styles/bootstrap.min.css'
import './styles/globals.css'
import './styles/font-awesome.min.css'
import './styles/normalize.css'
import './styles/header.css'
import './styles/footer.css'
// Below are local css
import './styles/frontpage.css'
import './styles/feed.css'
import './styles/model.css'
import './styles/singup-login.css'
import './styles/about.css'
import './styles/profile.css'
import './styles/single_profile.css'
import './styles/glory.css'
import './styles/pagination.css'
import "react-datetime/css/react-datetime.css";

// Admin css

import './styles/admin/admin.css'
import axios from "axios"
// import Store from "./helpers/context";

import Home from './pages/index'
import Singup from './pages/singup'
import Signin from './pages/signin'
import Reset from './pages/reset'
import About from './pages/about'
import Forget from './pages/forget'
import Glory from './pages/glory'
import Trade from './pages/trade'
import Recent from './pages/recent'



import Post from './pages/post'
import SinglePost from './pages/post/[id]'
import Profile from './pages/profile'
import SingleProfile from './pages/profile/[id]'
import Tags from './pages/tags/[id]'
import Leaderboard from './pages/leaderboard'
import Noticeboard from './pages/noticeboard'
import ThankYou from './pages/thank-you'


// Admin related 
import AdminPost from './pages/admin/post/index'
import AdminTrade from './pages/admin/trade/index'
import AdminSinglePost from './pages/admin/post/[id]'
import AdminSingleShame from './pages/admin/post/[id] shame'
import AdminSingleTrade from './pages/admin/trade/[id]'
import AdminComment from './pages/admin/comment'
import AdminFront from './pages/admin/index'
import AdminProfile from './pages/admin/profile'
import AdminUser from './pages/admin/user'
import AdminSingleViewUser from './pages/admin/user/single'
import AdminUserRank from './pages/admin/rank'
import Shame from './pages/shame'
import AdminSingleUser from './pages/admin/user/[id]'
import AdminLogout from './pages/admin/logout'
import Model from './components/model/model';
import EditNotice from './pages/admin/notice/edit-notice';
import ViewNotice from './pages/admin/notice/view-notice';



import { useContext } from 'react';
import { Context } from "./helpers/context"
if (typeof window !== "undefined") {
  const token = localStorage.getItem("jay.com")
  axios.defaults.headers.common["Authorization"] = token
}



function App() {
  const [user, setUser] = useContext(Context)

  return (
    <>
      {user.model && <Model />}

      <Routes>
        <Route exact path='/' element={<Home />} />
        <Route exact path='/singup' element={<Singup />} />
        <Route exact path='/signin' element={<Signin />} />
        <Route exact path='/reset' element={<Reset />} />
        <Route exact path='/forget' element={<Forget />} />
        <Route exact path='/about' element={<About />} />
        <Route exact path='/glory' element={<Glory />} />
        <Route exact path='/shame' element={<Shame />} />
        <Route exact path='/trades' element={<Trade />} />
        <Route exact path='/recent' element={<Recent />} />
        <Route exact path='/leaderboard' element={<Leaderboard />} />
        <Route exact path='/noticeboard' element={<Noticeboard />} />
        <Route exact path='/thank-you' element={<ThankYou />} />


        <Route exact path='/post' element={<Post />} />
        <Route exact path='/post/:id' element={<SinglePost />} />
        <Route exact path='/profile' element={<Profile />} />
        <Route exact path='/profile/:id' element={<SingleProfile />} />
        <Route exact path='/tags/:id' element={<Tags />} />



        {/* Admin related path */}


        <Route exact path='/admin' element={<AdminFront />} />
        <Route exact path='/admin/post' element={<AdminPost />} />
        <Route exact path='/admin/trade' element={<AdminTrade />} />
        <Route exact path='/admin/post/:id' element={<AdminSinglePost type={"glory"} />} />
        <Route exact path='/admin/post/shame/:id' element={<AdminSingleShame type={"shame"} />} />
        <Route exact path='/admin/trade/:id' element={<AdminSingleTrade />} />
        <Route exact path='/admin/comment' element={<AdminComment />} />
        <Route exact path='/admin/profile' element={<AdminProfile />} />
        <Route exact path='/admin/user' element={<AdminUser />} />
        <Route exact path='/admin/rank' element={<AdminUserRank />} />
        <Route exact path='/admin/notice' element={<ViewNotice />} />
        <Route exact path='/admin/add-notice' element={<EditNotice type="add" />} />
        <Route exact path='/admin/notice/:id' element={<EditNotice type="edit" />} />
        <Route exact path='/admin/user/single/:id' element={<AdminSingleViewUser />} />
        <Route exact path='/admin/user/:id' element={<AdminSingleUser />} />

        <Route exact path='/admin/logout' element={<AdminLogout />} />

      </Routes>


    </>);
}

export default App;
