import Layout from '../../../admin-component/layout'
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import axios from 'axios'
import { url } from "../../../helpers/url";
import Pagination from '../../../admin-component/pagination';
function ViewNotice({ type }) {
  const [row, setRow] = useState([])
  const [oldRow, setOldRow] = useState([])
  const [count, setCount] = useState([])
  const [oldCount, setOldCount] = useState([])
  const [load, setLoad] = useState(false)
  const [totalPages, setTotalPages] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)



  useEffect(() => {
    paginationData(currentPage)
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [currentPage]);



  const pagination = (item) => {
    setCurrentPage(item)
  }

  const postHandler = (id) => {
    axios.post(`${url}/admin/notice/`, { mode: 'delete', id: id }).then(({ data }) => {
      if (data.success) {
        const filter = row.filter(item => item.id !== id)
        setRow(filter)
      }
    })
  }


  function paginationData(id) {
    const page = (currentPage - 1) * 20;
    axios.get(`${url}/notice?page=${page}&mode=view`).then(({ data }) => {
      if (data.success) {
        setRow(data.data)
        // setOldRow(data.rows)
        // setCount(data.count)
        // setOldCount(data.count)
        // setLoad(true);
      }
    })
  }

  return (
    <Layout>

      <div className="admin_table">
        <div className="admin_body_header">
          <div className="left_header">
            <h3>Admin Noticeboard</h3>
          </div>
          {/* <div className="right_header">
            <input onChange={(e) => changeDelay(e.target.value)} type="text" name="" id="" placeholder='Search user' />
          </div> */}

        </div>

        {
          load && row.length === 0 && <div className="alert alert-danger">
            No user is found
          </div>
        }

        <table class="table table-hover table-striped left_table">
          <thead>
            <tr>
              <th>Notice</th>
              <th>Edit</th>
              <th>Delete</th>

            </tr>
          </thead>
          <tbody>
            {row.map((item, index) => {
              return (
                <tr key={item.id}>
                  <td>{item['notice']}</td>

                  <td><Link className='btn btn-warning' target={"_blank"} to={"/admin/notice/" + item.id}>Edit Notice</Link></td>

                  <td><button className='btn btn-danger' onClick={() => postHandler(item.id)}>Delete</button></td>
                </tr>
              )
            })}

            {totalPages > 1 &&
              <Pagination count={totalPages} page={currentPage} onClick={pagination} />
            }
          </tbody>
        </table>
      </div>
    </Layout>
  );
}

export default ViewNotice;