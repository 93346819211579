
const Pagination = ({ page, count = 0, onClick = function () { } }) => {
  const items = []

  for (let i = 1; i <= count; i++) {
    items.push(
      <li className="page-item" onClick={() => onClick(i)}>
        <div className={i === page ? "page-link active" : "page-link"}>
          {i}
        </div>
      </li>
    )
  }


  const nextHandler = () => {
    if (count <= page) onClick(page + 1)
  }

  return (
    <div className="pagination">
      <li className="page-item">
        <div className={page > 1 ? "page-link " : "page-link disabled"} onClick={() => onClick(page - 1)} aria-label="Previous" >
          &laquo;
        </div>
      </li>
      {items}

      <li className="page-item">
        <div className={page < count ? "page-link " : "page-link disabled"} aria-label="Next" onClick={() => onClick(page + 1)} >
          &raquo;
        </div>
      </li>

    </div>
  )
}

export default Pagination