import Layout from '../../admin-component/layout'
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import axios from 'axios'
import { url } from "../../helpers/url";
import Pagination from '../../admin-component/pagination';
import ReactHtmlParser from "react-html-parser"
function ViewUsers() {
  const [row, setRow] = useState([])
  const [oldRow, setOldRow] = useState([])
  const [count, setCount] = useState([])
  const [oldCount, setOldCount] = useState([])
  const [load, setLoad] = useState(false)

  const [totalPages, setTotalPages] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)

  useEffect(() => {
    paginationData(currentPage)
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [currentPage]);

  const postHandler = (id) => {
    axios.post(`${url}/admin/comment/`, { mode: 'delete', id: id }).then(({ data }) => {
      if (data.success) {
        const filter = row.filter(item => item.id !== id)
        setRow(filter)
      }
    })
  }

  function paginationData(currentPage) {
    const page = (currentPage - 1) * 20;
    axios.get(`${url}/admin/comment?page=${page}&mode=view`).then(({ data }) => {
      if (data.success) {
        setRow(data.rows)
        setOldRow(data.rows)
        setCount(data.count)
        setOldCount(data.count)
        setTotalPages(data.count)
        setLoad(true);
      }
    })
  }

  const pagination = (item) => {
    setCurrentPage(item)
  }




  const [timer, setTimer] = useState(null);

  const changeDelay = (change) => {

    if (change.length) {

      if (timer) {
        clearTimeout(timer);
        setTimer(null);
      }
      setTimer(
        setTimeout(() => {
          axios.get(`${url}/admin/search?search=${change}&type=comment`)
            .then(({ data }) => {
              setRow(data.rows)
              setTotalPages(0);
              setCurrentPage(1)
            });
        }, 1000)
      );
    }
    if (change.length === 0) {
      setRow(oldRow)
      setTotalPages(oldCount);
      setCurrentPage(1)
    }
  }



  return (
    <Layout>

      <div className="admin_table">
        <div className="admin_body_header">
          <div className="left_header">
            <h3>Comment List</h3>
          </div>
          <div className="right_header">
            <input onChange={(e) => changeDelay(e.target.value)} type="text" name="" id="" placeholder='Search comment' />
          </div>

        </div>

        {
          load && row.length === 0 && <div className="alert alert-danger">
            No comment is found
          </div>
        }

        <table class="table table-hover table-striped left_table">
          <thead>
            <tr>
              <th>Comment</th>
              <th>Image</th>
              <th>View User</th>
              <th>Delete</th>
            </tr>
          </thead>
          <tbody>
            {row.map((item, index) => {
              return (
                <tr key={item.id}>
                  <td>{ReactHtmlParser(item.comment)}</td>
                  <td>{item.image && <Link to={item.image}><img className='comment_admin_img' src={item.image} alt="" /> </Link>}</td>
                  <td><Link className='btn btn-warning' target={"_blank"} to={"/profile/" + item.userId}>View User</Link></td>
                  <td className='cursor' onClick={() => postHandler(item.id)}><p className='btn btn-dark'>Delete</p></td>
                </tr>
              )
            })}

            {totalPages > 1 &&
              <Pagination count={totalPages} page={currentPage} onClick={pagination} />
            }
          </tbody>
        </table>
      </div>
    </Layout>
  );
}

export default ViewUsers;