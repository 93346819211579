import Header from '../helpers/header/header'
import { useState, useEffect } from 'react'
import { url } from '../helpers/url';
import axios from 'axios';
import Pagination from '../admin-component/pagination'
import { Link } from 'react-router-dom';
import { todayIs } from '../helpers/functions'
function Recent() {
  const [load, setLoad] = useState(false)
  const [row, setRow] = useState([])
  const [count, setCount] = useState([])
  const [totalPages, setTotalPages] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  useEffect(() => {

  }, [])


  useEffect(() => {
    paginationData(currentPage)
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [currentPage]);


  function paginationData() {
    const page = (currentPage - 1) * 20;
    axios.get(url + `/post/glory?page=${page}`)
      .then(({ data }) => {
        if (data.success) {
          setRow(data.rows)
          setTotalPages(data.count)
        }
        setLoad(true)
      })
  }


  const pagination = (item) => {
    setCurrentPage(item)
  }


  return (
    <>
      <Header>
        <div className="col-lg-9 body_col glory">
          <h3 className='title'>Halls of Glory</h3>
          <h4 className='title glory_sub'>Top Market Calls by Members</h4>
          {(load && row.length === 0) &&
            <div className="custom_alert alert alert-danger">
              No posts are found
            </div>
          }
          {(load && row.length > 0) && <>

            <div className="table-responsive">
              <table class="front_table table table-hover table-striped left_table table-dark">
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Member</th>
                    <th>Market Call</th>
                    <th>Percentage Gain</th>
                    <th>View Post</th>
                  </tr>
                </thead>
                <tbody>
                  {row.map((item, index) => {
                    return (
                      <tr key={item.id}>
                        <td>{todayIs(item.createdAt)}</td>
                        <td>{item['registers.username']}</td>
                        <td>{item.feature_text}</td>
                        <td className='gain_percentage'>{item.gain}%</td>
                        <td><Link target="_blank" className="btn glory_btn" to={"/post/" + item.id}>View Post</Link></td>
                      </tr>
                    )
                  })}


                </tbody>
              </table>
            </div>

          </>
          }
          {totalPages > 1 &&
            <Pagination count={totalPages} page={currentPage} onClick={pagination} />
          }
        </div>
      </Header>

    </>
  );
}

export default Recent;