import Header from '../helpers/header/header'
import { useState, useEffect, useContext } from 'react'
import { url } from '../helpers/url';
import axios from 'axios';
import Pagination from '../admin-component/pagination'
import { Link } from 'react-router-dom';
import { todayIs, todayAsia, todayUs } from '../helpers/functions'
import ModelReadMore from '../components/model/trade_model'
import { Context } from '../helpers/context';
import Trade from '../components/trade/trade'
function Recent() {
  const [load, setLoad] = useState(false)
  const [row, setRow] = useState([])
  const [user, setUser] = useContext(Context)

  const [totalPages, setTotalPages] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)

  // const [marketDate, setMarketDate] = useState()

  // useEffect(() => {
  //   axios.get(url + `/post/glory?page=0&type=trade`)
  //     .then(({ data }) => {
  //       if (data.success) {
  //         setRow(data.rows)
  //         setOldRow(data.rows)
  //         setCount(data.count)
  //         setOldCount(data.count)
  //         setTotalPages(data.count);
  //         // let findDate = data.rows.find(item => item.method === 'Dynamic')
  //         // if (findDate) {
  //         //   setMarketDate(todayUs(findDate.updatedAt))
  //         // }
  //       }
  //       setLoad(true)
  //     })
  // }, [])



  useEffect(() => {
    paginationData(currentPage)
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [currentPage]);


  function paginationData(currentPage) {
    setLoad(false)
    const page = (currentPage - 1) * 20;
    axios.get(`${url}/post/glory?page=${page}&type=trade`)
      .then(({ data }) => {
        setRow(data.rows);
        setTotalPages(data.count)
        setLoad(true);
      })
  }


  const pagination = (item) => {
    setCurrentPage(item)
  }


  return (
    <>
      {(!user.userId && load) && <ModelReadMore />}
      <Header>
        <div className="col-lg-9 body_col glory">
          <h3 className='title'>Current Trades</h3>
          {/* {(load && marketDate && user.userId) &&
            <h4 className='title glory_sub'>Trade Performance as of close of market {marketDate}</h4>
          } */}
          {(load && row.length === 0 && user.userId) &&
            <div className="custom_alert alert alert-danger">
              No posts are found
            </div>
          }
          {(load) && <Trade data={row} />}
          {totalPages > 1 &&
            <Pagination count={totalPages} page={currentPage} onClick={pagination} />
          }
        </div>
      </Header>


    </>
  );
}

export default Recent;